<template>
  <ion-grid v-if="visibleSettings.length > 0">
    <ion-row class="ion-padding-bottom">
      <ion-col  size="8">
        <strong>{{ $t('conditions.alert settings.Alert name') }}</strong>
      </ion-col>
      <ion-col  size="3">
        <strong>{{ $t('conditions.alert settings.Device group amount') }}</strong>
      </ion-col>
      <ion-col size="1">
      </ion-col>
    </ion-row>
    <SettingItem v-for="setting in visibleSettings"
                 :key="setting.uuid"
                 :setting="setting"
                 @edit="$emit('openForm',setting)"
                 @refetch="refetch" />
  </ion-grid>
  <ion-grid v-else>
     <img :src="$asset('/images/icons/Bell.svg')" class="image-bell" />
  </ion-grid>
</template>

<script>
import SettingItem from './SettingItem'
import { alertQueries } from '~gro-modules/Alert'
import { computed } from 'vue'

export default {
  props: {
    alertType: {
      required: true,
      type: String,
    },
  },
  components: { SettingItem },
  setup (props) {
    const { settings, loading, refetch } = alertQueries.getSettings()
    const visibleSettings = computed(() => {
      return settings.value.filter((setting) => setting.type === props.alertType)
        .sort((settingA, settingB) => settingA.name > settingB.name)
    })
    const expanded = computed(() => {
      return visibleSettings.value.every(setting => setting.isOpen)
    })

    return {
      settings,
      refetch,
      loading,
      visibleSettings,
      expanded,
    }
  },
  mounted () {
    this.refetch()
  },
}
</script>
<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid var(--ion-color-light)
  }
  .image-bell {
  height: 150px;
  display: flex;
    margin: auto;
}
</style>
