<template>
  <ion-segment class="tab-segment" :value="active" mode="ios">
    <ion-segment-button class="range-segment-button" value="36 hour" @click="select(36, 'hour',3, 180)">
      <ion-label>{{ $t('conditions.ranges.36 hours') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button class="range-segment-button" value="3 day" @click="select(3, 'day',3, 600)">
      <ion-label>{{ $t('conditions.ranges.3 days') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button class="range-segment-button" value="7 day" @click="select(7, 'day',  6 ,1800)">
      <ion-label>{{ $t('conditions.ranges.7 days') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button class="range-segment-button" value="30 day" @click="select(30, 'day',  60,1800)">
      <ion-label>{{ $t('conditions.ranges.30 days') }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script>
export default {
  emits: ['update:range'],
  props: {
    range: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      active: '',
      defaults: {
        interval: 36,
        unit: 'hour',
        windowInterval: 3,
        refreshSeconds: 180,
      },
    }
  },
  watch: {
    range (range) {
      this.active = `${range.interval} ${range.unit}`
    },
  },

  methods: {
    async select (interval, unit, windowInterval, refreshSeconds = 10) {
      this.active = `${interval} ${unit}`
      await this.$wait(0.2)
      this.$emit('update:range', {
        liveModeInterval: refreshSeconds * 1000,
        interval,
        unit,
        windowInterval,
      })
    },
  },
  mounted () {
    if (!this.range.unit) {
      this.select(this.defaults.interval, this.defaults.unit, this.defaults.windowInterval, this.defaults.refreshSeconds)
    } else {
      this.active = `${this.range.interval} ${this.range.unit}`
    }
  },
}
</script>
<style lang="scss" scoped>
.tab-segment {
  padding: 8px 8px 8px 16px;
  ion-segment-button:first-of-type  {
    border-radius: 3px 0px 0px 3px;
  }
  ion-segment-button:last-of-type  {
    border-radius: 0px 3px 3px 0px  ;
  }
}
ion-segment {
  --background: transparent;
}

ion-segment-button {
  height: 38px;
  max-width: 96px;
  font-weight: bold;
  letter-spacing: 0.6px;
  font-size: 0.75rem;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --background: var(--color-range-segment-background);
  --border-width: 1px;
  --border-color: var(--color-range-segment-border);
  --border-radius: 0px;
  --color: var(--color-range-segment-color);
  --color-checked: var(--color-range-segment-checked);
  --indicator-color: var(--color-range-segment-indicator);
  --indicator-box-shadow: transparent;
}
</style>
