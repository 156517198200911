<template>
  <ion-text padding>
    <h3 no-margin>{{ $t(`conditions.alert settings.${alertType}.Ranges`) }}</h3>
  </ion-text>
  <ion-grid>
    <ion-row v-for="(setting, index) in rangeSettings" :key="index" class="ion-padding-top ion-align-items-center">

      <ion-col size="2">
          <span class="input-label"
                v-html="$t(`sensor.types.${setting.sensorType}.label`)" />
        <ion-input type="number"
                   class="ion-float-left"
                   inputmode="decimal"
                   :min="setting.limits.min"
                   :max="setting.range.upper"
                   :step="setting.limits.stepSize"
                   v-model="setting.range.lower"
                   @update:modelValue="rangesUpdated"
                   @ionBlur="updateRangeValue" />
        <span class="input-suffix"> {{ setting.unit }}</span>
      </ion-col>
      <ion-col size="8">
        <ion-range dual-knobs pin no-padding
                   :disabled="setting.autoScale"
                   :min="setting.limits.min"
                   :max="setting.limits.max"
                   :step="setting.limits.stepSize"
                   :color="setting.limits.min === setting.range.upper ? 'medium' : setting.color"
                   v-model="setting.range"
                   @update:modelValue="rangesUpdated"
        />
      </ion-col>
      <ion-col size="2">
        <ion-input type="number"
                   inputmode="decimal"
                   :min="setting.range.min"
                   :max="setting.limits.lower"
                   :step="setting.limits.stepSize"
                   v-model="setting.range.upper"
                   @update:modelValue="rangesUpdated"
                   @ionBlur="updateRangeValue" />
        <span class="input-suffix"> {{ setting.unit }}</span>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { ref } from 'vue'
import { SensorTypeHelper } from '~gro-modules/Sensor'

const TYPE_RANGES = {
  BATTERY: ['BATTERY_PERCENTAGE'],
  CLIMATE: ['TEMP', 'RH', 'CO2', 'VPD'],
  ROOTZONE: ['WC', 'EC', 'ROOT_TEMP'],
  NO_DATA: [],
}

export default {
  emits: ['update:ranges'],
  props: {
    ranges: {
      type: Array,
      required: true,
    },
    alertType: {
      type: String,
      required: true,
    },
  },
  setup () {
    const rangeSettings = ref([])
    return { rangeSettings }
  },
  methods: {
    rangesUpdated () {
      this.$emit('update:ranges', this.rangeSettings.filter(setting => {
        return setting.limits.min !== setting.range.upper
      }).map(setting => ({
        uuid: setting.uuid,
        sensorType: setting.sensorType,
        lowerThreshold: setting.range.lower * (setting.limits.conversion ?? 1),
        upperThreshold: setting.range.upper * (setting.limits.conversion ?? 1),
      })))
    },
    updateRangeValue () {
      this.rangeSettings.forEach(setting => {
        const range = this.ranges.find(range => range.sensorType === setting.sensorType)
        if (!range) {
          return
        }
        setting.range = {
          lower: range.lowerThreshold / (setting.limits.conversion ?? 1),
          upper: range.upperThreshold / (setting.limits.conversion ?? 1),
        }
      })
    },
  },
  async mounted () {
    this.rangeSettings = await Promise.all(TYPE_RANGES[this.alertType].map(async (sensorType) => {
      const limits = await SensorTypeHelper.getSettingAsync(sensorType, 'axisLimits', {
        min: 0,
        max: 100,
        stepSize: 1,
      })
      const setting = this.ranges.find(range => range.sensorType === sensorType) || {
        lowerThreshold: limits.min,
        upperThreshold: limits.min,
        uuid: null,
      }
      const label = SensorTypeHelper.$t(sensorType, 'label')
      const unit = SensorTypeHelper.$t(sensorType, 'unit')
      const color = SensorTypeHelper.getColorName(sensorType)
      return {
        uuid: setting.uuid,
        range: {
          lower: setting.lowerThreshold / (limits.conversion ?? 1),
          upper: setting.upperThreshold / (limits.conversion ?? 1),
        },
        limits,
        sensorType,
        label,
        color,
        unit,
      }
    }))
  },
}
</script>
<style lang="scss" scoped>
ion-input {
  background-color: var(--ion-color-primary-contrast);
  --padding-end: 2px;
  border: 1px solid rgb(211, 214, 223);
  border-radius: 2px;
}

.input-suffix {
  position: absolute;
  right: 35px;
  top: 20px;
  z-index: 2;
  font-size: 10px;
}

.input-label {
  position: absolute;
  top: -15px;
  z-index: 3;
  font-size: 10px;
}

ion-range {
  --knob-size: 30px;
  --bar-height: 3px;
}

.range-has-pin {
  padding-top: 12px !important;
}
</style>
