import IndexPage from './IndexPage'

export default [
  {
    path: '/conditions',
    name: 'conditions.index',
    component: IndexPage,
  },
  {
    path: '/conditions/:uuid',
    name: 'conditions.show',
    component: IndexPage,
    props: true,
  },
]
