<template>
  <ion-select v-if="selectedGroupUuid && groups.length"
              class="select-devicegrouptype"
              interface="popover"
              :interface-options="customPopoverOptions"
              :placeholder="$t('conditions.groupSelect.Main')"
              color="light"
              v-model="selectedGroupUuid"
              @ionChange="selectGroup">
    <ion-select-option v-for="selectableGroup in selectableGroups"
                       :key="selectableGroup.uuid"
                       :value="selectableGroup.uuid"
                       :disabled="(selectableGroup.uuid !== UNASSIGNED_UUID && !otherGroups.find(otherGroup=> otherGroup.uuid === selectableGroup.uuid)) || selectableGroup.uuid === selectedGroupUuid">
      {{ selectableGroup.name }}
    </ion-select-option>
  </ion-select>
</template>

<script>
import { computed, ref } from 'vue'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import { AvailableTypesHelper } from '~gro-modules/DeviceGroup'
import { Translator } from '~gro-plugins'

export const UNASSIGNED_UUID = 'unassigned'

export default {
  emits: ['update:group', 'update:sensorTypes'],
  props: {
    group: {
      type: Object,
      default: () => {
        return {
          uuid: null,
          types: [],
        }
      },
    },
    groups: {
      type: Array,
      required: true,
    },
    otherGroups: {
      type: Array,
      required: true,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const selectableGroups = computed(() => {
      return [
        ...props.groups,
        {
          name: Translator.translate('dashboard.device.Unassigned'),
          uuid: UNASSIGNED_UUID,
        },
      ]
    })
    const selectedGroupUuid = ref(null)
    const customPopoverOptions = {
      cssClass: 'popover-devicegrouptype',
      animated: false,
      showBackdrop: false,
    }
    return {
      selectableGroups,
      selectedGroupUuid,
      timer: null,
      customPopoverOptions,
      UNASSIGNED_UUID,
    }
  },
  watch: {
    groups () {
      this.assureGroupSelection()
    },
    autoSelect () {
      if (this.autoSelect) {
        this.timer = setInterval(() => {
          const currentIndex = this.selectableGroupss.findIndex((group) => group.uuid === this.selectedGroupUuid)
          const nextIndex = currentIndex + 1
          this.selectedGroupUuid = this.selectableGroupss[nextIndex % this.selectableGroups.length].uuid
          this.selectGroup()
        }, 30 * 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    '$route.params.uuid' () {
      this.assureGroupSelection()
    },
  },
  methods: {
    async selectGroup () {
      await this.$wait(0.2)
      if (this.group.uuid === this.selectedGroupUuid &&
        this.group.visible &&
        this.group.types
      ) {
        return
      }
      const group = this.selectableGroups.find(group => group.uuid === this.selectedGroupUuid)
      this.$emit('update:group', {
        ...group,
        visible: true,
        types: SensorTypeHelper.chartRenderableTypes.map(type => ({
          name: type,
          value: null,
        })),
      })
      const availableTypes = AvailableTypesHelper.load(group.uuid)
      this.$emit('update:sensorTypes',
        SensorTypeHelper.chartRenderableTypes.map(type => {
          return {
            name: type,
            enabled: true,
            available: availableTypes[type] !== undefined,
            zone: SensorTypeHelper.getZoneForType(type),
          }
        }),
      )
    },
    assureGroupSelection () {
      this.selectedGroupUuid = this.group.uuid
      if (!this.groups.length) return null
      if (this.selectedGroupUuid) this.selectGroup()
      else if (this.$route.params.uuid) {
        this.selectedGroupUuid = this.$route.params.uuid
        this.selectGroup()
      } else {
        this.selectedGroupUuid = this.selectableGroups[0].uuid
        this.selectGroup()
      }
    },
  },
  mounted () {
    this.assureGroupSelection()
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
}
</script>
<style lang="scss">
.popover-devicegrouptype {
  ion-select-popover ion-list ion-item ion-radio {
    --color: transparent;
    --color-checked: transparent;
  }

  .popover-content {
    max-height: 400px;
    margin-top: 9px !important;
  }

  ion-item {
    font-size: 1rem;
    font-weight: 600;
    --min-height: 38px;
    --padding-top: 0px;
    --border-color: transparent;
  }
}
</style>
<style lang="scss" scoped>
.select-devicegrouptype {
  height: 38px;
  font-size: 1rem;
  background-color: var(--ion-color-light-shade);
  font-weight: 600;
  padding: 8px 8px 8px 16px;
  width: 250px;
  border: 0.5px solid #999;
  border-radius: 3px;
}

ion-select::part(icon) {
  opacity: 1;
  margin-left: 1em;
  color: transparent;
  background: url('/images/chevron-down-outline.svg') no-repeat center;
}
</style>
