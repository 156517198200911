<template>
  <ion-segment :value="highlight">
    <ion-segment-button v-for="(source, index) in selection"
                        :value="index"
                        :key="source.id"
                        @click="highlightSource(index)"
                        mode="md">
      <ion-label>{{ source.name }}</ion-label>
    </ion-segment-button>
    <ion-button @click="openMenu($event)"
                fill="clear"
                color="cto"
                class="button-add no-hover">
      <ion-icon name="add" />
    </ion-button>
  </ion-segment>
</template>

<script>
import { computed, ref } from 'vue'
import { deviceQueries } from '~gro-modules/Device'
import SourcePopover from './SourcePopover'
import { UNASSIGNED_UUID } from './DeviceGroupSelect'

export default {
  emits: ['update:compared-devices', 'update:compared-groups', 'update:group'],
  props: {
    group: {
      type: Object,
      required: true,
    },
    comparedDevices: {
      type: Object,
      required: true,
    },
    comparedGroups: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: 7,
    },
  },
  setup (props) {
    const { devices: allDevices } = deviceQueries.getWithGroups()
    const devices = computed(() => {
      if (props.group?.uuid === UNASSIGNED_UUID) {
        return allDevices.value.filter(device => !device.group)
      }
      return allDevices.value.filter(device => device.group?.uuid === props.group?.uuid)
    })
    const highlight = ref(0)
    return { devices, highlight }
  },
  computed: {
    selection () {
      const selection = [
        ...this.comparedGroups.map(group => ({
          type: 'group',
          name: group.name,
          uuid: group.uuid,
        })),
        ...this.comparedDevices.map(device => ({
          type: 'device',
          name: device.serialNumber,
          serialNumber: device.serialNumber,
        })),
      ]

      if (this.group.visible) {
        selection.unshift({
          type: 'group',
          name: this.$t('conditions.comparison.Average'),
          uuid: this.group.uuid,
        })
      }
      return selection
    },
  },
  methods: {
    async openMenu (event) {
      this.highlight = null
      const changes = {}
      await this.$showInPopover(event, SourcePopover, {
        ...this.$props,
        devices: this.devices,
        emitUp: (name, payload) => {
          changes[name] = payload
        },
      }, {
        cssClass: 'comparisonPopover',
        animated: false,
      })

      Object.keys(changes).forEach(event => {
        this.$emit(event, changes[event])
      })
      this.highlightSource(0)
    },
    highlightSource (index) {
      this.highlight = index
      this.$emit('highlightChanged', this.selection[index])
    },
  },
}
</script>

<style scoped lang="scss">
ion-segment {
  height: 38px;
  display: block;
  text-align: left;
  width: 100%;

  ion-segment-button {
    --indicator-color: var(--ion-color-cto);
    display: inline-block;
    font-size: 0.875rem;
    letter-spacing: 0.6px;
    font-weight: bold;
    text-transform: none;
  }
}

.button-add {
  vertical-align: top;
}
</style>
