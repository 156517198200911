<template>
  <chart v-if="chartOptions"
         :options="chartOptions"
         :series="series"
         :axis="yAxis"
         ref="chart"
         :loading="dataLoading.value"
         :highlight="highlight" />
</template>

<script>
import moment from 'moment-timezone'
import Chart from '~gro-components/Chart'
import { deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { deviceQueries } from '~gro-modules/Device'
import { ref, reactive, watchEffect, computed } from 'vue'
import { GraphComposer } from '~gro-modules/Graph'
import chartOptions from './chartOptions'
import { id as WC } from '~gro-modules/Sensor/types/WC'
import { id as TEMP } from '~gro-modules/Sensor/types/TEMP'
import { id as ROOT_TEMP } from '~gro-modules/Sensor/types/ROOT_TEMP'

export default {
  emits: ['update:loading'],
  props: {
    settings: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: null,
    },
    highlight: {
      type: Object,
      default: null,
    },
  },
  setup (props, { emit }) {
    const chart = ref()
    const seriesRange = reactive({
      from: moment.utc().subtract(props.settings.range.interval, props.settings.range.unit),
      to: moment.utc(),
    })
    watchEffect(() => {
      seriesRange.from = moment.utc().subtract(props.settings.range.interval, props.settings.range.unit)
      seriesRange.to = moment.utc()
    })
    const groupFilter = computed(() => {
      const filter = props.settings.comparedGroups.map(group => group.uuid)
      if (props.settings.group.visible) filter.push(props.settings.group.uuid)
      return filter
    })
    const deviceGroupSeriesVariables = computed(() => {
      return {
        interval: props.settings.range.windowInterval,
        unit: 'MINUTE',
        search: {
          filter: groupFilter.value,
        },
        sensorTypes: props.settings.sensorTypes.map(type => type.name),
        from: seriesRange.from.format('YYYY-MM-DD HH:mm:ss'),
        to: seriesRange.to.format('YYYY-MM-DD HH:mm:ss'),
      }
    })
    const deviceSeriesVariables = computed(() => {
      return {
        interval: props.settings.range.windowInterval,
        unit: 'MINUTE',
        search: { filter: props.settings.comparedDevices.map(group => group.serialNumber) },
        sensorTypes: props.settings.sensorTypes.map(type => type.name),
        from: seriesRange.from.format('YYYY-MM-DD HH:mm:ss'),
        to: seriesRange.to.format('YYYY-MM-DD HH:mm:ss'),
      }
    })

    const deviceGroupSeriesQuery = deviceGroupQueries.getMeasurementWindowForGroups(deviceGroupSeriesVariables)

    const navigatorSeriesVariables = computed(() => {
      const availableTypes = props.settings.sensorTypes.filter(type => type.available)
      let type = availableTypes[0]?.name
      if (availableTypes.find(type => type.name === WC)) {
        type = WC
      } else if (availableTypes.find(type => type.name === TEMP)) {
        type = TEMP
      } else if (availableTypes.find(type => type.name === ROOT_TEMP)) {
        type = ROOT_TEMP
      }

      return {
        interval: 5,
        unit: 'HOUR',
        search: {
          filter: [props.settings.group.uuid],
        },
        sensorTypes: [type],
        to: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        from: moment().utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss'),
      }
    })

    const deviceSeriesQuery = deviceQueries.getMeasurementWindowForDevices(deviceSeriesVariables)
    const navigatorSeriesQuery = deviceGroupQueries.getMeasurementWindowForGroups(navigatorSeriesVariables)
    const windows = computed(() => {
      let windows = []
      if (props.settings.comparedDevices.length) {
        windows = [
          ...deviceSeriesQuery.windows.value,
          ...windows,
        ]
      }
      if (groupFilter.value.length) {
        windows = [
          ...deviceGroupSeriesQuery.windows.value,
          ...windows,
        ]
      }
      return windows
    })

    return (new GraphComposer('conditions')).setup(props, emit, chart, {
      chartOptions,
      windows,
      navigatorWindow: navigatorSeriesQuery.windows,
      seriesRange,
      deviceGroupSeriesQuery,
      deviceSeriesQuery,
      deviceGroupSeriesVariables,
      deviceSeriesVariables,
      navigatorSeriesVariables,
      chart,
    })
  },
  components: {
    Chart,
  },
}
</script>
<style lang="scss">
.highcharts-navigator-mask-inside {
  fill: rgb(70, 81, 211);
  fill-opacity: 0.12;
  stroke: rgb(70, 81, 211);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
  rx: 5;
}

.highcharts-navigator-mask-outside {
  fill-opacity: 0;
  stroke: rgb(227, 232, 239);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
  rx: 5;
}
</style>
