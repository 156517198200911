<template>
  <ion-button @click="openModal" fill="solid" color="cto" class="button-alert">
    <ion-icon name="notifications" />
    <ion-label>{{ $t('conditions.Alert settings') }}</ion-label>
  </ion-button>

  <ion-modal :is-open="modalOpen" :backdropDismiss="false" cssClass="modal-alertsettings">
    <ion-content class="ion-padding">
      <div class="modal-header">
        <ion-toolbar>
          <ion-title slot="start">{{ $t('conditions.alert settings.Title') }}
          </ion-title>
          <ion-buttons slot="end">
            <ion-button @click="dismiss">
              <ion-icon slot="start" name="close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar>
          <ion-segment v-model="alertType" :disabled="editing">
            <ion-segment-button v-for="type in alertTypes" class="alert-segment-button" :key="type" :value="type">
              <ion-label>{{ $t(`conditions.alert settings.types.${type}`) }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </div>
      <div class="modal-content">
        <div class="settings-container">
          <SettingsList v-if="!editing"
                        :alert-type="alertType"
                        @openForm="openForm" />
          <SettingForm v-else
                       :device-groups="deviceGroups"
                       :alert-type="alertType"
                       v-model:settings="form" />
          <ion-row class="buttons-form ion-padding-bottom" v-if="editing">
            <ion-col>
            </ion-col>
            <ion-col class="seamless ">
              <ion-button @click="closeForm()" color="dark" class="seamless footer" fill="clear">
                {{ $t('common.Cancel') }}
              </ion-button>
            </ion-col>
            <ion-col class="seamless ">
              <ion-button @click="saveForm()" color="cto" class="seamless footer" fill="solid">
                {{ form.uuid ? $t(`common.Save`) : $t(`conditions.alert settings.Add alert`) }}
              </ion-button>
            </ion-col>
            <ion-col>
            </ion-col>
          </ion-row>
          <ion-row v-else>
            <ion-col></ion-col>
            <ion-col class="seamless ">
              <ion-button @click="openForm()" color="cto" class="seamless footer" expand="full">
                <ion-icon slot="start" name="add" />
                {{ $t('conditions.alert settings.New') }}
              </ion-button>
            </ion-col>
            <ion-col>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <ion-toolbar class="seamless modal-footer">
        <ion-row>
          <ion-col class="seamless ">
            <ion-button @click="dismiss()" color="cto" :disabled="editing" class="seamless footer" expand="full">
              {{ $t('common.Done') }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref } from 'vue'
import { alertMutations } from '~gro-modules/Alert'
import { ROOTZONE_ALERT, CLIMATE_ALERT } from '~gro-modules/Alert/types'
import SettingsList from './alert/SettingsList'
import SettingForm from './alert/SettingForm'
import { deviceGroupQueries } from '~gro-modules/DeviceGroup'

export default {
  components: { SettingsList, SettingForm },
  setup () {
    const modalOpen = ref(false)
    const editing = ref(false)
    const form = ref({})
    const alertType = ref(ROOTZONE_ALERT)
    const alertTypes = [
      ROOTZONE_ALERT,
      CLIMATE_ALERT,
    ]

    const { groups: deviceGroups, loading } = deviceGroupQueries.getAll('name', 'uuid')
    return {
      alertType,
      alertTypes,
      form,
      editing,
      modalOpen,
      loading,
      deviceGroups,
    }
  },
  methods: {
    openModal () {
      this.modalOpen = true
      this.editing = false
    },
    openForm (model = { name: '', isEnabled: true, devices: [], ranges: [], maxChangeSettings: [] }) {
      this.form = model
      this.editing = true
    },
    async saveForm () {
      const errors = await this.storeSettings()
      if (errors) return this.$showGraphQLErrors(errors)
      this.$toast('common.Saved')
      this.closeForm()
    },
    async storeSettings () {
      const input = {
        alertType: this.alertType,
        name: this.form.name,
        isEnabled: this.form.isEnabled,
        maxChangeSettings: this.form.maxChangeSettings.map(setting => ({
          sensorType: setting.sensorType,
          maxDrop: setting.maxDrop,
          maxRise: setting.maxRise,
          uuid: setting.uuid,
          timeWindow: setting.timeWindow,
        })),
        ranges: this.form.ranges.map(range => ({
          uuid: range.uuid,
          sensorType: range.sensorType,
          lowerThreshold: range.lowerThreshold,
          upperThreshold: range.upperThreshold,
        })),
        deviceGroups: this.form.deviceGroups.map(group => ({
          uuid: group.uuid,
        })),
        devices: [],
      }

      if (this.form.uuid) {
        const { errors } = await alertMutations.updateSetting({
          uuid: this.form.uuid,
          ...input,
        })
        return errors
      }

      const { errors } = await alertMutations.createSetting(input)
      return errors
    },
    closeForm () {
      this.editing = false
    },
    dismiss () {
      this.modalOpen = false
    },
  },
}
</script>
<style lang="scss" scoped>
.button-alert {
  padding: 0 12px;
  margin: 0 !important;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  height: 38px;
  --border-radius: 3px !important;

  ion-icon {
    margin-right: 10px;
  }
}

.modal-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  --min-height: 36px;
}

.modal-header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
}

.modal-content {
  padding: 112px 0 40px 0;
  overflow: hidden;
  height: 100%;
}

.settings-container {
  height: 100%;
  overflow-y: auto;
}

.buttons-form {
  background-color: var(--ion-color-light);

  ion-button {
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    --ripple-color: transparent;
  }
}
ion-segment {
  --background: transparent;
}

ion-segment-button {
  height: 38px;
  max-width: 140px;
  font-weight: bold;
  letter-spacing: 0.6px;
  font-size: 0.75rem;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --background: var(--color-range-segment-background);
  --background-checked:var(--color-range-segment-color);
  --border-width: 1px;
  --border-color: var(--color-range-segment-border);
  --border-radius: 0px;
  --color: var(--color-range-segment-color);
  --color-checked: var(--color-range-segment-checked);
  --indicator-color : var(--color-range-segment-indicator);
  --indicator-box-shadow: transparent;
}
</style>
<style lang="scss">
.modal-alertsettings {
  @media only screen and (min-width: 768px) and (min-height: 600px) {
    --width: 768px;
  }
}
</style>
