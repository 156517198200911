<template>
  <ion-text padding v-if="changeSettings.length">
    <h3 no-margin>{{ $t(`conditions.alert settings.${alertType}.Max changes`) }}</h3>
  </ion-text>
  <ion-grid v-for="(setting,index) in changeSettings" :key="index">
    <ion-row>
      <ion-col size="2">
        <ion-label :key="`max-change-label-${index}`">
          {{ $t(`sensor.types.${setting.sensorType}.label`) }} {{ $t('conditions.alert settings.max changes.Drop') }}
        </ion-label>
        <ion-input v-model="setting.maxDrop"
                   @update:modelValue="settingsUpdated"
                   placeholder="100"
                   type="number" />
        <span class="input-suffix"> {{ $t(`sensor.types.${setting.sensorType}.unit`) }} </span>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script>
import moment from 'moment'
import { ref } from 'vue'

const TYPE_RANGES = {
  CLIMATE: [],
  ROOTZONE: ['WC'],
}
export default {
  emits: ['update:settings'],
  props: {
    settings: {
      type: Object,
      required: true,
    },
    alertType: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const changeSettings = ref(TYPE_RANGES[props.alertType].map((sensorType) => {
      const setting = props.settings.find(setting => setting.sensorType === sensorType) || {
        sensorType,
        maxDrop: null,
        maxRise: null,
        uuid: null,
        timeWindow: 3600000,
      }
      return {
        ...setting,
        window: moment(setting.timeWindow).utc().format('HH:mm'),
      }
    }))

    return { changeSettings }
  },
  methods: {
    windowChanged (setting, time) {
      if (!time) return
      setting.timeWindow = moment.duration(time, 'h:mm').asMilliseconds()
      this.settingsUpdated()
    },
    settingsUpdated () {
      this.$emit('update:settings', this.changeSettings.map(setting => ({
        uuid: setting.uuid,
        sensorType: setting.sensorType,
        maxDrop: setting.maxDrop,
        maxRise: setting.maxRise,
        timeWindow: setting.timeWindow,
      })))
    },
  },
}
</script>
<style lang="scss" scoped>
ion-input {
  background-color: var(--ion-color-primary-contrast);
  --padding-end: 2px;
  border: 1px solid rgb(211, 214, 223);
  border-radius: 2px;
}

.input-suffix {
  position: absolute;
  right: 35px;
  bottom: 18px;
  z-index: 2;
  font-size: 10px;
}
</style>
