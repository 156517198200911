<template>
  <ion-button @click="this.dismiss"
              fill="clear"
              class="button-close">
    <ion-icon slot="icon-only" name="close-outline" />
  </ion-button>
  <ion-content class="ion-padding">

    <ion-toolbar>
      <ion-title slot="start">
        {{ `Data sources (${amountSelected} / ${devices.length + 1 > max ? max : devices.length + 1})` }}
      </ion-title>
      <ion-buttons slot="end">
        <ion-button color="cto"
                    fill="clear"
                    :disabled="noSelection"
                    @click="deselectAll">
          {{ $t('conditions.comparison.Deselect all') }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-grid>
      <ion-row class="ion-margin-top">
        <ion-col v-if="!isUnassignedGroup">
          <ion-badge class="legend-sensor-toggle"
                     @click="toggleMainGroup"
                     :color="mainGroup.visible ? 'cto' : 'light'">
            <ion-label>{{ $t('conditions.comparison.Average') }}</ion-label>
          </ion-badge>
        </ion-col>
      </ion-row>
      <template v-for="typeGroup in typeGroups" :key="typeGroup">
        <ion-row v-if="devicesPerGroup[typeGroup]"
                 class="ion-margin-top">
          <span class="select-list-title">{{ $t(`device.typeGroups.${typeGroup}`) }}</span>
        </ion-row>
        <ion-row>
          <ion-badge class="legend-sensor-toggle"
                     v-for="(device, index) in devicesPerGroup[typeGroup]"
                     :key="device.serialNumber"
                     @click="toggleDevice(typeGroup, index, device)"
                     :color="isSelected(device) ? 'cto' : 'light'">
            <ion-label>{{ device.serialNumber }}
              <template v-if="!device.group">
                <br>
                <small>{{ $t(`device.Unassigned`) }}</small>
              </template>
              <template v-else-if="device.group.uuid !== group.uuid">
                <br>
                <small>{{ device.group.name }}</small>
              </template>
            </ion-label>
          </ion-badge>
        </ion-row>
      </template>
    </ion-grid>
  </ion-content>
</template>

<script>
import { SensorTypeHelper } from '~gro-modules/Sensor'
import { deviceQueries, deviceSensorTypeGroup } from '~gro-modules/Device'
import { typeGroups } from '~gro-modules/Device/typeGroups'
import { computed, reactive, watch, ref } from 'vue'
import { UNASSIGNED_UUID } from './DeviceGroupSelect'

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
    comparedDevices: {
      type: Object,
      required: true,
    },
    comparedGroups: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: 7,
    },
    emitUp: {
      type: Function,
      required: true,
    },
    dismiss: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    const isUnassignedGroup = ref(props.group?.uuid === UNASSIGNED_UUID)
    const { devices: allDevices } = deviceQueries.getWithGroups()
    const devices = computed(() => {
      return allDevices.value
        .filter(device => {
          if (isUnassignedGroup.value) {
            return !device.group
          }
          return device.group?.uuid === props.group?.uuid
        })
        .map(device => ({
          ...device,
          typeGroup: deviceSensorTypeGroup(device.type),
          visible: !!props.comparedDevices.find(compareDevice => compareDevice.serialNumber === device.serialNumber),
          types: SensorTypeHelper.filterChartRenderableTypes(device.sensors.map(sensor => sensor.type))
            .map(type => ({
              name: type,
              value: null,
            })),
        }))
    })
    const mainGroup = reactive({
      ...props.group,
      visible: props.comparedDevices.length === 0 || props.group.visible,
    })

    const selectedDevices = ref([])
    watch(devices, () => {
      selectedDevices.value = [...devices.value.filter(device => device.visible)]
    }, {
      immediate: true,
    })

    const devicesPerGroup = computed(() => {
      return devices.value.reduce((perGroup, device) => {
        if (!perGroup[device.typeGroup]) perGroup[device.typeGroup] = []
        perGroup[device.typeGroup].push(device)
        return perGroup
      }, [])
    })

    return {
      mainGroup,
      devicesPerGroup,
      selectedDevices,
      devices,
      isUnassignedGroup,
      typeGroups,
    }
  },
  computed: {
    noSelection () {
      return this.selectedDevices.length === 0
    },
    amountSelected () {
      return this.selectedDevices.length + (this.mainGroup.visible ? 1 : 0)
    },
    maxSelected () {
      return this.amountSelected >= this.max
    },
  },
  methods: {
    deselectAll () {
      this.selectedDevices.forEach(device => {
        device.visible = false
      })
      this.selectedDevices = []
      this.mainGroup.visible = true
      this.$forceUpdate()
      this.emitUp('update:group', this.mainGroup)
      this.emitUp('update:compared-devices', [])
    },
    toggleMainGroup () {
      if (this.noSelection || (!this.mainGroup.visible && this.maxSelected)) return
      this.mainGroup.visible = !this.mainGroup.visible
      this.$forceUpdate()
      this.emitUp('update:group', this.mainGroup)
    },
    isSelected (device) {
      return this.selectedDevices.find(selected => selected.serialNumber === device.serialNumber)
    },
    async toggleDevice (group, index, device) {
      if (this.maxSelected && !device.visible) return
      if (device.visible && this.amountSelected === 1) return
      device.visible = !device.visible
      if (device.visible) {
        this.selectedDevices = [
          ...this.selectedDevices,
          device,
        ]
      } else {
        this.selectedDevices = this.selectedDevices.filter(selectedDevice => selectedDevice.serialNumber !== device.serialNumber)
      }
      this.$forceUpdate()
      this.emitUp('update:compared-devices', this.selectedDevices)
    },
  },
}
</script>

<style lang="scss">
.comparisonPopover {
  .popover-content {
    right: 0;
    top: 0 !important;
    bottom: 0 !important;
    left: inherit !important;
    width: 100%;
    max-width: 640px;
    max-height: inherit !important;
    background: transparent;
    box-shadow: none;
  }

  ion-content {
    position: absolute;
    right: 0px;
    width: calc(100% - 52px);
  }

  ion-title {
    font-size: 1.375rem;
    font-weight: bold;
    font-weight: 700;
  }

  .legend-sensor-toggle {
    margin-top: 5px;
  }

  .button-close {
    position: absolute;
    top: 10px;

    ion-icon {
      font-size: 32px;
      color: #000;
    }
  }
}
</style>
