// Vue dependencies
import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import { DefaultApolloClient } from '@vue/apollo-composable'

// Grosens files
import '~gro-styling'
import '~gro-helpers/Logger'
import {
  Analytics,
  apolloClient,
  dialogsMixin,
  Gate,
  GrosensComponents,
  navigationMixin,
  Storage,
  Translator,
  AppStatus,
  svgInjectDirective,
  waitMixin,
  assetMixin,
} from '~gro-plugins'

import LocalStorage from '~gro-plugins/storageAdapters/LocalStorage'
import { BridgeRepository, connectionType } from '~gro-modules/Bridge'

// App files
import router, { loginRoute } from './router'
import App from './App.vue'
import appComponents from './components/common'

if (process.env.VUE_APP_CLOUD_VIEWER === 'true') {
  BridgeRepository.setAvailableConnections([connectionType.VPN, connectionType.CLOUD])
} else {
  BridgeRepository.setAvailableConnections([connectionType.LOCAL])
}

async function boot () {
  const app = createApp(App)
    .use(IonicVue, {
      rippleEffect: false,
    })
    .use(router)
    .use(Storage, LocalStorage)
    .provide(DefaultApolloClient, apolloClient)
    .use(GrosensComponents)
    .use(AppStatus)
    .use(Gate)
    .use(Translator)
    .use(appComponents)
    .mixin(dialogsMixin)
    .mixin(navigationMixin)
    .mixin(waitMixin)
    .mixin(assetMixin)
    .use(svgInjectDirective)
    .use(Analytics)
  app.config.devtools = true

  await router.isReady()
  window.$log('%cRouter is ready', 'font-weight:bold')
  await BridgeRepository.getCurrent()
  await Analytics.start()
  if (await BridgeRepository.bridgeIsSet()) {
    await Gate.resetUser()
    if (await Storage.has('conditionsView') && window.location.pathname === '/') {
      router.push({ name: 'conditions.index' })
    }
  } else if (window.location.pathname === '/') {
    router.push(loginRoute)
  }

  await GrosensComponents.loadGrowerType(true)
  await Translator.reloadLanguage()
  app.mount('#app')
}

boot()
