import { baseChartOptions } from '~gro-modules/Graph'
// import { SensorTypeHelper } from '~gro-modules/Sensor'

export default function () {
  return baseChartOptions({
    chart: {
      type: 'spline',
      panning: false,
      pinchType: 'none',
      resetZoomButton: false,
      zoomtype: 'none',
      animation: false,
      alignTicks: true,
    },
    navigator: {
      enabled: true,
      margin: 10,
      height: 30,
      adaptToUpdatedData: false,
      handles: {
        enabled: false,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        tickInterval: 24 * 3600 * 1000,
        dateTimeLabelFormats: {
          day: '%e',
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    series: [],
  }, ...arguments)
}
