<template>
  <div class="settings-form ion-padding">
    <ion-form>
      <ion-text>
        <h3>
          {{ $t(`conditions.alert settings.${isNew ? 'Create' : 'Edit'}`) }}
        </h3>
      </ion-text>
      <ion-label> {{ $t(`conditions.alert settings.Name`) }}</ion-label>
      <ion-item>
        <ion-input v-model="formCopy.name"
                   @update:modelValue="$emit('update:settings', formCopy)"
                   :placeholder="$t('conditions.alert settings.Name')" />
      </ion-item>
    </ion-form>
    <ion-grid class="ion-no-padding ion-padding-top">
      <ion-row class="ion-padding-bottom"
               v-for="(group, index) in formCopy.deviceGroups"
               :key="group.uuid">
        <ion-col size="5" class="ion-padding-end">
          <ion-label> {{ $t(`conditions.alert settings.Select group`) }}</ion-label>
          <ion-select v-model="formCopy.deviceGroups[index]"
                      :placeholder="$t('common.Select')"
                      @ionChange="$emit('update:settings', formCopy)">
            <ion-select-option v-for="selectableGroup in [ group, ...selectableGroups]"
                               :key="selectableGroup.uuid"
                               :value="selectableGroup">
              {{ selectableGroup.name }}
            </ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="6" class="ion-padding-end" />
        <ion-col size="1">
          <ion-button v-if="formCopy.deviceGroups.length > 1"
                      @click="deleteGroup(group.uuid)"
                      fill="clear"
                      color="danger"
                      class="button-deletegroup">
            <ion-icon name="remove" />
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-button @click="addGroup" fill="clear" color="cto"
                  :disabled="formCopy.deviceGroups.length === deviceGroups.length">
        <ion-icon name="add" />
        {{ $t(`conditions.alert settings.Add room`) }}
      </ion-button>
    </ion-grid>
    <ion-grid>
      <AlertRanges v-model:ranges="formCopy.ranges"
                   @update:ranges="$emit('update:settings', formCopy)"
                   :alert-type="alertType" />
      <MaxChangeSettings v-model:settings="formCopy.maxChangeSettings"
                         @update:settings="$emit('update:settings', formCopy)"
                         :alert-type="alertType" />
    </ion-grid>
  </div>
</template>

<script>
import { watch, computed, reactive } from 'vue'
import AlertRanges from './AlertRanges'
import MaxChangeSettings from './MaxChangeSettings'

export default {
  components: { AlertRanges, MaxChangeSettings },
  emits: ['update:settings'],
  props: {
    settings: {
      type: Object,
      required: true,
    },
    deviceGroups: {
      type: Array,
      required: true,
    },
    alertType: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const isNew = computed(() => !props.settings.uuid)
    const prepareSettings = settings => ({
      ...settings,
      deviceGroups: settings.devices.reduce((groups, device) => {
        if (!groups.find(group => group.uuid === device.group?.uuid)) {
          groups.push(device.group)
        }
        return groups
      }, []),
      devices: [],
    })

    const formCopy = reactive(prepareSettings(props.settings))

    watch(props.settings, settings => {
      formCopy.value = prepareSettings(settings)
    })

    const selectableGroups = computed(() => {
      return props.deviceGroups.filter(group => {
        return !formCopy.deviceGroups.find(selectedGroup => group.uuid === selectedGroup.uuid)
      })
    })

    return {
      isNew,
      formCopy,
      selectableGroups,
    }
  },
  methods: {
    addGroup () {
      if (!this.selectableGroups.length) return
      this.formCopy.deviceGroups.push(this.selectableGroups[0])
      this.$emit('update:settings', this.formCopy)
    },
    deleteGroup (uuid) {
      this.formCopy.deviceGroups = this.formCopy.deviceGroups.filter(group => group.uuid !== uuid)
      this.$emit('update:settings', this.formCopy)
    },
  },
  async mounted () {
    await this.$nextTick()
    this.addGroup()
  },
}
</script>

<style scoped>
.settings-form {
  background: var(--ion-color-light);
}
</style>
