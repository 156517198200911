<template>
  <ion-modal
    :is-open="modalOpen"
    :backdropDismiss="false"
  >
    <ion-content class="ion-padding">
      <ion-toolbar class="modal-header">
        <ion-title slot="start">{{ $t('conditions.axisSettings.Title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="cancel">
            <ion-icon slot="start" name="close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <div class="modal-content">
        <div class="settings-container">
          <p>{{ $t('conditions.axisSettings.Description') }}</p>
          <ion-grid>
            <ion-row v-for="(setting, index) in axisSettings"
                     :key="index"
                     class="ion-align-items-center ion-padding-bottom">
              <ion-col size="3">
                <ion-label class="label">{{ setting.label }}:</ion-label>
                <ion-radio-group v-model="setting.autoScale">
                  <ion-item lines=none>
                    <ion-label>{{ $t('conditions.axisSettings.Auto scale') }}</ion-label>
                    <ion-radio :value="true" />
                  </ion-item>
                </ion-radio-group>
              </ion-col>
              <ion-col size="3">
                <ion-label>&nbsp;</ion-label>
                <ion-radio-group v-model="setting.autoScale">
                  <ion-item lines=none>
                    <ion-label>{{ $t('conditions.axisSettings.Fixed') }}</ion-label>
                    <ion-radio :value="false" />
                  </ion-item>
                </ion-radio-group>
              </ion-col>
              <ion-col size="3" padding>
                <ion-label class="label" position="stacked">{{ $t('settings.export_old.From') }}</ion-label>
                <ion-input type="number"
                           inputmode="decimal"
                           :min="setting.limits.min"
                           :max="setting.range.upper"
                           :step="setting.limits.stepSize"
                           v-model="setting.range.lower"
                           :disabled="setting.autoScale" />
              </ion-col>
              <ion-col size="3" padding>
                <ion-label class="label" position="stacked">{{ $t('settings.export_old.To') }}</ion-label>
                <ion-input type="number"
                           inputmode="decimal"
                           :min="setting.range.lower"
                           :max="setting.limits.max"
                           :step="setting.limits.stepSize"
                           v-model="setting.range.upper"
                           :disabled="setting.autoScale" />
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
      <ion-toolbar class="seamless modal-footer">
        <ion-row>
          <ion-col class="seamless ">
            <ion-button color="light" class="seamless footer" expand="full" @click="cancel">
              {{ $t('common.Cancel') }}
            </ion-button>
          </ion-col>
          <ion-col class="seamless">
            <ion-button class="seamless footer" expand="full" color="cto" @click="save">
              {{ $t('common.Done') }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref } from 'vue'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import authMutations from '~gro-modules/Auth/mutations'

export default {
  emits: ['axisChanged'],
  setup () {
    const modalOpen = ref(false)
    const sliderActive = ref(false)
    const formGroups = ref([])
    const axisSettings = ref([])

    return {
      modalOpen,
      formGroups,
      axisSettings,
      sliderActive,
    }
  },
  computed: {
    settings () {
      return this.axisSettings.reduce((settings, axis) => {
        settings[axis.sensorType] = {
          sensorType: axis.sensorType,
          min: parseFloat(axis.range.lower),
          max: parseFloat(axis.range.upper),
          autoScale: axis.autoScale,
        }
        return settings
      }, {})
    },
  },
  methods: {
    openModal () {
      this.modalOpen = true
    },
    async setSliders () {
      const types = SensorTypeHelper.chartRenderableTypes.filter(type => type === 'EC' || type === 'WC')
      this.axisSettings = await Promise.all(types.map(async (sensorType) => {
        const limits = await SensorTypeHelper.getSettingAsync(sensorType, 'axisLimits', {
          min: 0,
          max: 100,
          stepSize: 1,
        })
        const setting = await this.$bridge.user.getSetting(`axis.${sensorType}`, {
          autoScale: true,
          min: limits.min,
          max: limits.max,
        })
        const label = SensorTypeHelper.$t(sensorType, 'label')
        const color = SensorTypeHelper.getColorName(sensorType)
        return {
          autoScale: setting.autoScale,
          range: { lower: setting.min, upper: setting.max },
          limits,
          sensorType,
          label,
          color,
        }
      }))
    },
    async save () {
      const errors = await authMutations.updateSettings({ axis: Object.values(this.settings) })
      if (errors) return this.$showGraphQLErrors(errors)

      this.dismiss()
      return this.$toast('common.Saved')
    },
    async cancel () {
      await this.$bridge.user.reloadSettings()
      this.dismiss()
      return this.setSliders()
    },
    dismiss () {
      this.$emit('axisChanged')
      this.modalOpen = false
    },
  },
  async mounted () {
    return this.setSliders()
  },
}
</script>
<style lang="scss" scoped>
.button-photoperiod {
  padding: 0 16px;
  --background: var(--color-button-photoperiod);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  ion-icon {
    opacity: 1;
    color: transparent;
    background: url('/images/icons/PHOTOPERIOD-WHITE_20x20.svg') no-repeat center;
    margin-right: 10px;
  }
}

.modal-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  --min-height: 36px;
}

.modal-header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}

.modal-content {
  padding: 56px 0 40px 0;
  overflow: hidden;
  height: 100%;
}

.settings-container {
  height: 100%;
  overflow-y: auto;
}

.current {
  border-bottom: 1px solid var(--ion-color-light);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

ion-item {
  --background-hover-opacity: 0;
}

ion-input {
  border: 1px solid var(--ion-color-medium);
  border-radius: 3px;
}

.label {
  font-size: 14px;
  font-weight: 800;
  color: var(--ion-color-medium);
}
</style>
