<template>
  <ion-page id="index">
    <DesktopHeader />
    <ion-content>
      <ion-grid class="content-grid" v-for="(settings, index) in settingGroups" :key="settings.uuid">
        <ion-toolbar class="ion-justify-content-center toolbar-top">
          <DeviceGroupSelect v-if="!unassignedLoading"
                             v-model:group="settings.group"
                             v-model:sensorTypes="settings.sensorTypes"
                             :groups="groups"
                             :otherGroups="otherGroups"
                             @update:group="onGroupSelect(index, $event)"
                             slot="start" />
          <RangeSelect v-model:range="settings.range" />
          <ion-buttons v-if="index === 0" slot="end" class="ion-justify-content-start buttons-chart">
            <PhotoPeriodSettings v-if="$can('edit', 'deviceGroup')"
                                 v-model:group="settings.group"
                                 @update:group="refetchGroups"
                                 :groups="groups" />
            <AlertSettings />
            <ion-button fill="clear" class="button-ellipsis-horizontal" slot="end" @click="openPopover($event, index)"
                        :disabled="!charts.length">
              <ion-icon name="ellipsis-horizontal" />
            </ion-button>
          </ion-buttons>
          <ion-buttons v-else slot="end" class="buttons-chart">
            <ion-button slot="end" fill="clear" color="cto" @click="removeChart(index)" class="button-add-chart">
              <ion-icon name="remove-outline" />
              {{ $t('conditions.Remove chart') }}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar>
          <SourceList v-model:compared-devices="settings.comparedDevices"
                      v-model:group="settings.group"
                      v-model:compared-groups="settings.comparedGroups"
                      @highlightChanged="updateHighlight(index, $event)"
                      :groups="otherGroups" />
        </ion-toolbar>
        <div class="chart-container" :class="{dense: multiChart}">
          <ConditionsChart v-if="settings.group.uuid && timezone"
                           :settings="settings"
                           :height="multiChart ? 300 : 600"
                           v-model:loading="loadingGroups[index]"
                           :dense="multiChart"
                           :highlight="highlights[index]"
                           :timezone="timezone"
                           :ref="(el) => setChartRef(el, index)" />
        </div>
        <div class="zone-toggles">
          <ZoneToggles v-model:sensorTypes="settings.sensorTypes" />
        </div>
        <ExportMenu v-if="settings.group?.uuid" :settings="settings" :ref="setExportRef" />
      </ion-grid>
    </ion-content>
    <AxisSettings ref="axisSettingsModal" @axisChanged="refreshAxis" />
  </ion-page>
</template>

<script>
import AlertSettings from './partials/settings/AlertSettings'
import AxisSettings from './partials/settings/AxisSettings'
import ConditionsChart from './partials/ConditionsChart'
import SourceList from './partials/selection/SourceList'
import DeviceGroupSelect, { UNASSIGNED_UUID } from './partials/selection/DeviceGroupSelect'
import ExportMenu from './partials/ExportMenu'
import PhotoPeriodSettings from './partials/settings/PhotoPeriodSettings'
import RangeSelect from './partials/RangeSelect'
import ZoneToggles from '~gro-components/ZoneToggles'
import { AvailableTypesHelper, deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { reactive, ref } from 'vue'
import { bridgeQueries } from '~gro-modules/Bridge'
import Highcharts from 'highcharts/highstock'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import { deviceQueries } from '~gro-modules/Device'

export default {
  setup () {
    const { groups, refetch } = deviceGroupQueries.getAllWithDevices()
    const { devices: unassignedDevices, loading: unassignedLoading } = deviceQueries.getUnassigned(null)
    const { _meta, timezone: baseTimezone } = bridgeQueries.getSettings('timezone')
    const timezone = ref(baseTimezone.value)
    _meta.onResult(({ data }) => {
      if (!data.bridge.settings.timezone) {
        timezone.value = moment.tz.guess()
      } else {
        timezone.value = data.bridge.settings.timezone
      }

      Highcharts.setOptions({
        global: {
          time: {
            useUTC: false,
            timezone: timezone.value,
          },
        },
      })
    })

    const settingGroups = reactive([{
      uuid: uuidv4(),
      range: {},
      group: {},
      comparedGroups: [],
      comparedDevices: [],
      sensorTypes: [],
    }])
    const loadingGroups = ref([true])
    const popover = ref(null)
    const charts = ref([])
    const highlights = ref([null])
    const exportModals = ref([])
    const setChartRef = (el, index) => {
      if (!el || charts.value.includes(el)) return
      charts.value[index] = el
    }
    const setExportRef = el => {
      if (!el || exportModals.value.includes(el)) return
      exportModals.value.push(el)
    }
    return {
      refetchGroups: refetch,
      setChartRef,
      setExportRef,
      charts,
      highlights,
      exportModals,
      groups,
      unassignedDevices,
      loadingGroups,
      unassignedLoading,
      settingGroups,
      timezone,
      popover,
      maxCharts: 2,
    }
  },
  components: {
    AlertSettings,
    AxisSettings,
    ConditionsChart,
    DeviceGroupSelect,
    ExportMenu,
    PhotoPeriodSettings,
    RangeSelect,
    SourceList,
    ZoneToggles,
  },
  computed: {
    otherGroups () {
      return this.groups.filter(group => {
        return !this.settingGroups.find(settings => settings.group.uuid === group.uuid)
      })
    },
    multiChart () {
      return this.settingGroups.length > 1
    },
  },
  watch: {
    settingGroups: {
      handler () {
        this.storeSettings()
      },
      deep: true,
    },
  },
  methods: {
    async openPopover ($event, index = 0) {
      const options = []
      options.push({
        text: this.$t('conditions.Add chart'),
        disabled: !(this.groups.length - this.maxCharts !== this.otherGroups.length),
        handler: () => this.addChart(),
        icon: 'add-outline',
      })
      options.push({
        text: this.$t('conditions.AxisSettings'),
        handler: () => this.$refs.axisSettingsModal.openModal(),
        icon: 'pencil-outline',
      })
      if (this.exportModals[index] && this.$can('exportData', 'bridge')) {
        options.push({
          text: this.$t('conditions.export.Label'),
          handler: () => this.exportModals[index].prepareExport('CSV'),
          icon: 'arrow-undo-outline',
        })
      }
      this.popover = await this.$dropdownMenu($event, options)
    },
    onGroupSelect (index, group) {
      if (group.uuid === UNASSIGNED_UUID) {
        this.settingGroups[index].comparedDevices = this.unassignedDevices
        this.settingGroups[index].group.visible = false
      } else {
        this.settingGroups[index].comparedDevices = []
      }
      this.settingGroups[index].comparedGroups = []
    },
    refreshAxis () {
      this.charts.forEach(chart => chart.refreshAxisLimits())
    },
    updateHighlight (index, source) {
      this.highlights[index] = source
    },
    addChart () {
      if (this.groups.length - this.maxCharts === this.otherGroups.length) return
      if (this.otherGroups.length === 0) return
      this.charts[0].chart.setHeight(300)
      this.loadingGroups.push(true)
      this.highlights.push(null)

      const availableTypes = AvailableTypesHelper.load(this.otherGroups[0].uuid)
      this.settingGroups.push({
        uuid: uuidv4(),
        range: {},
        group: {
          ...this.otherGroups[0],
          visible: true,
          types: SensorTypeHelper.chartRenderableTypes.map(type => ({
            name: type,
            value: null,
          })),
        },
        comparedGroups: [],
        comparedDevices: [],
        sensorTypes: SensorTypeHelper.chartRenderableTypes.map(type => {
          return {
            name: type,
            enabled: true,
            available: availableTypes[type] !== undefined,
            zone: SensorTypeHelper.getZoneForType(type),
          }
        }),
      })
    },
    removeChart (index) {
      this.charts.splice(index, 1)
      this.loadingGroups.splice(index, 1)
      this.settingGroups.splice(index, 1)
      this.highlights.splice(index, 1)
      if (this.charts.length === 1) {
        this.charts[0].chart.setHeight(600)
      }
    },
    storeSettings () {
      this.$storage.set('conditionsView',
        this.settingGroups,
      )
    },
    async loadSettings () {
      if (!await this.$storage.has('conditionsView')) {
        return
      }
      const settingGroups = await this.$storage.get('conditionsView')
      if (!Array.isArray(settingGroups) || settingGroups.length === 0) {
        return
      }
      if (this.$route.params.uuid && this.$route.params.uuid !== settingGroups[0].group?.uuid) {
        return
      }
      const validGroups = settingGroups.filter(settingGroup => {
        return !!settingGroup.uuid &&
          !!settingGroup.range &&
          !!settingGroup.group &&
          Array.isArray(settingGroup.comparedGroups) &&
          Array.isArray(settingGroup.comparedDevices) &&
          Array.isArray(settingGroup.sensorTypes)
      })
      if (validGroups.length === 0) {
        return
      }
      this.removeChart(0)
      validGroups.forEach(settingGroup => {
        this.settingGroups.push(settingGroup)
        this.loadingGroups.push(true)
        this.highlights.push(null)
      })
    },
  },
  async mounted () {
    return this.loadSettings()
  },
}
</script>
<style lang="scss" scoped>
.buttons-chart {
  min-width: 310px;
  justify-content: flex-end;

  ion-button {
    height: 38px;
  }
}

.toolbar-top {
  margin: 32px 0px 32px 0px;
}

.content-grid {
  padding: 0 24px 0 24px;
}
</style>
