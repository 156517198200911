<script>
import { ref } from 'vue'
import { exportMutations, exportQueries, exportStatus } from '~gro-modules/Export'
import moment from 'moment'
import { newExports } from '~root/modules/ExportsState'

export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const previousStatuses = ref([])
    return { previousStatuses }
  },
  methods: {
    async prepareExport (fileType) {
      await this.$toast('conditions.export.Started')
      this.previousStatuses = await exportQueries.getStatuses()
      const fromDate = moment.utc().subtract(180, 'days')
      const data = {
        from: fromDate.format('YYYY-MM-DD HH:mm:ss'),
        to: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        deviceGroups: [this.settings.group.uuid, ...this.settings.comparedGroups.map(group => group.uuid)],
        devices: this.settings.comparedDevices.map(device => device.serialNumber),
        fileType,
      }
      const result = await exportMutations.prepare(data)
      if (result.errors) return this.$showGraphQLErrors(result.errors)
      this.waitUntilExportsComplete()
    },
    async waitUntilExportsComplete () {
      await new Promise(resolve => setTimeout(resolve, 1000))
      const newStatuses = await exportQueries.getStatuses()

      newStatuses.filter(newExport => newExport.status === exportStatus.DONE)
        .forEach(finishedExport => {
          const previousStatus = this.previousStatuses.find(previous => previous.uuid === finishedExport.uuid)
          if (finishedExport.status !== previousStatus?.status) {
            newExports.value.push(finishedExport.uuid)
          }
        })

      if (newStatuses.some(({ status }) => [exportStatus.PENDING, exportStatus.STARTED].includes(status))) {
        this.waitUntilExportsComplete()
      }
    },
  },
}
</script>
