<template>
  <ion-button class="button-photoperiod" fill="solid" @click="openModal" v-if="group.uuid">
    <ion-icon />
    <ion-label>{{ $t('conditions.photoPeriod.Button') }}</ion-label>
  </ion-button>

  <ion-modal :is-open="modalOpen" :backdropDismiss="false">
    <ion-content class="ion-padding">
      <ion-toolbar class="modal-header">
        <ion-title slot="start">{{ $t('conditions.photoPeriod.Title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="start" name="close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <div class="modal-content">
        <div class="device-groups-list">
          <ion-text>
            <p>{{ $t('conditions.photoPeriod.Description') }}</p>
          </ion-text>
          <ion-grid>
            <ion-row>
              <ion-col></ion-col>
              <ion-col>{{ $t('conditions.photoPeriod.Lights on') }}</ion-col>
              <ion-col>{{ $t('conditions.photoPeriod.Photoperiod time') }}</ion-col>
              <ion-col>{{ $t('conditions.photoPeriod.Lights Off') }}</ion-col>
            </ion-row>
            <ion-row v-for="(group, index) in formGroups" :key="group.uuid" v-bind:class="{ current: index === 0}">
              <ion-col class="group-name">
                <ion-label>{{ group.name }}</ion-label>
              </ion-col>
              <ion-col>
                <ion-input class="border"
                           v-model="group.lightsOn"
                           maxlength="5"
                           placeholder="--:--     24h"
                           @ionInput="groupDateInput(index, 'ligthsOn', $event)"
                           @ionBlur="forceGroupDate(index, 'ligthsOn')" />
              </ion-col>
              <ion-col>
                <ion-input class="border"
                           v-model="group.period"
                           maxlength="5"
                           placeholder="--:--     hrs"
                           @ionInput="groupDateInput(index, 'period', $event)"
                           @ionBlur="forceGroupDate(index, 'period')" />
              </ion-col>
              <ion-col class="group-lightsoff" v-if="group.lightsOff">{{ group.lightsOff }}</ion-col>
              <ion-col class="group-lightsoff" v-else>{{ '--:--' }}</ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
      <ion-toolbar class="seamless modal-footer">
        <ion-row>
          <ion-col v-if="!noBack" class="seamless ">
            <ion-button @click="dismiss" color="light" class="seamless footer" expand="full">
              {{ $t('common.Close') }}
            </ion-button>
          </ion-col>
          <ion-col v-if="!noPrimary" class="seamless">
            <ion-button @click="save" color="cto" class="seamless footer" expand="full">
              {{ $t('common.Save') }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref } from 'vue'
import moment from 'moment'
import { deviceGroupMutations } from '~gro-modules/DeviceGroup'

export default {
  emits: ['update:group'],
  setup () {
    const modalOpen = ref(false)
    const rifraf = ref('')
    const formGroups = ref([])
    return {
      modalOpen,
      formGroups,
      rifraf,
    }
  },
  props: {
    group: {
      type: Object,
      default: () => {
        return {
          uuid: null,
          types: [],
        }
      },
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openModal () {
      this.formGroups = [
        this.group,
        ...this.groups.filter(group => group.uuid !== this.group.uuid),
      ]
        .filter(group => group.uuid !== 'unassigned')
        .map(group => {
          const lightsOff = moment(group.lightsOff, 'HH:mm:ss')
          const lightsOn = moment(group.lightsOn, 'HH:mm:ss')
          const duration = moment.duration(lightsOff.diff(lightsOn))
          return {
            ...group,
            lightsOff: group.lightsOff ? lightsOff.format('H:mm') : null,
            lightsOn: group.lightsOn ? lightsOn.format('H:mm') : null,
            period: group.lightsOff && group.lightsOn ? moment(duration.asMilliseconds()).utc().format('H:mm') : null,
          }
        })
      this.modalOpen = true
    },
    updateTime (input, group, attribute) {
      if (attribute === 'ligthsOn') {
        group.lightsOn = input
        group.lightsOff = group.period ? moment(input, 'H:mm').add(group.period).format('H:mm') : null
      } else if (attribute === 'ligthsOff') {
        group.lightsOff = input
        group.lightsOn = group.period ? moment(input, 'H:mm').subtract(group.period).format() : null
      } else if (attribute === 'period') {
        group.period = input
        group.lightsOff = group.lightsOn ? moment(group.lightsOn, 'H:mm').add(input).format('H:mm') : null
      }
    },
    groupDateInput (groupIndex, attribute, $event) {
      const value = $event.target.value.replace(/[^0-9]/g, '').slice(0, 4)
      let time = value.replace(/^([0-1][0-9]|2[0-3]|[0-9])([0-5][0-9])(.*)/, '$1:$2')
      this.formGroups[groupIndex][attribute] = time
      if (time.length > 2) {
        if (time.indexOf(':') === -1) time = time.slice(0, 2)
        else this.updateTime(time, this.formGroups[groupIndex], attribute)
      }
    },
    forceGroupDate (groupIndex, attribute) {
      if (!this.formGroups[groupIndex]) return
      let time = this.formGroups[groupIndex][attribute]
      if (!time) return
      if (time.indexOf(':') === -1) {
        if (time.length <= 2) time = `${time}:00`
        this.formGroups[groupIndex][attribute] = time.replace(/^([0-1][0-9]|2[0-3]|[0-9])([0-5][0-9])(.*)/, '$1:$2')
      }
      if (this.formGroups[groupIndex][attribute].match(/^([0-1][0-9]|2[0-3]|[0-9]):([0-5][0-9])$/)) {
        this.updateTime(time, this.formGroups[groupIndex], attribute)
      } else {
        this.formGroups[groupIndex][attribute] = ''
      }
    },
    async save () {
      this.dismiss()
      const deviceGroups = this.formGroups.map(group => ({
        uuid: group.uuid,
        lightsOn: group.lightsOn ? moment(group.lightsOn, 'H:mm').format('H:mm') : null,
        lightsOff: group.lightsOff ? moment(group.lightsOff, 'H:mm').format('H:mm') : null,
      }))

      const result = await deviceGroupMutations.updateDeviceGroups(deviceGroups)
      if (result.errors) return this.$showGraphQLErrors(result.errors)
      this.$emit('update:group', {
        ...this.group,
        lightsOn: deviceGroups[0].lightsOn,
        lightsOff: deviceGroups[0].lightsOff,
      })
      return this.$toast('common.Saved')
    },
    dismiss () {
      this.modalOpen = false
    },
  }
  ,
}
</script>
<style lang="scss" scoped>
.button-photoperiod {
  padding: 0 12px;
  margin: 0 !important;
  --background: var(--color-button-photoperiod);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  height: 38px;
  --border-radius: 3px !important;

  ion-icon {
    opacity: 1;
    color: transparent;
    background: url('/images/icons/PHOTOPERIOD-WHITE_20x20.svg') no-repeat center;
    margin-right: 10px;
  }
}

.modal-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  --min-height: 36px;
}

.modal-header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}

.modal-content {
  padding: 56px 0 40px 0;
  overflow: hidden;
  height: 100%;
}

.device-groups-list {
  height: 100%;
  overflow-y: auto;
}

.group-name {
  align-items: center;
  display: flex;
  color: var(--ion-color-dark);
  font-size: 1rem;
  font-weight: bold;
  line-height: 37px;
}

.current {
  border-bottom: 1px dashed var(--ion-color-light);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.border {
  border: 1px solid var(--ion-color-light)
}

.group-lightsoff {
  margin: auto;
}
</style>
