<template>
  <div>
    <ion-row :class="{ 'setting-item-disabled': !setting.isEnabled }"
             class="border-bottom ion-justify-content-center ion-align-items-center">
      <ion-col size="8">
        <ion-toggle mode="ios" :checked="setting.isEnabled" @ionChange="toggleEnabled" />
        <strong class="ion-padding-start"> {{ setting.name }}</strong>
      </ion-col>
      <ion-col size="3">
        <strong> {{ deviceGroups.length }}</strong>
      </ion-col>
      <ion-col size="1">
        <ion-button @click="toggleOpen" color="cto" fill="clear">
          <ion-icon :name="isOpen? 'chevron-up-outline':'chevron-down-outline'" />
        </ion-button>
      </ion-col>
    </ion-row>
    <div v-if="isOpen" class="drawer">
      <ion-row class="ion-padding-top">
        <ion-col>
          {{ $t('deviceGroup.Plural') }}:
        </ion-col>
        <ion-col>{{ deviceGroupString }}</ion-col>
      </ion-row>
      <ion-row v-for="range in setting.ranges" :key="range.uuid">
        <ion-col v-html="$t(`sensor.types.${range.sensorType}.label`) + ':'" />
        <ion-col>
          <sensor-value noLabel
                        :colorized="false"
                        :value="range.lowerThreshold"
                        :type="range.sensorType" />
          -
          <sensor-value noLabel
                        :colorized="false"
                        :value="range.upperThreshold"
                        :type="range.sensorType" />
        </ion-col>
      </ion-row>
      <ion-row v-for="maxChange in maxChangeSettings" :key="maxChange.uuid">
        <ion-col>{{ $t(`sensor.types.${maxChange.sensorType}.label`) }}
          {{ $t('conditions.alert settings.max changes.Drop') }}:
        </ion-col>
        <ion-col>
          {{ maxChange.maxDrop }}
        </ion-col>
      </ion-row>
      <ion-row class="border-bottom">
        <ion-col>
          <ion-button fill="clear" class="ion-float-right" color="primary" @click="openPopover($event)">
            <ion-icon name="ellipsis-vertical" slot="start"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { alertMutations } from '~gro-modules/Alert'
import SensorValue from '~gro-components/SensorValue'
import moment from 'moment'

export default {
  components: { SensorValue },
  emits: ['refetch', 'edit'],
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const isOpen = ref(false)
    return { isOpen }
  },
  computed: {
    deviceGroups () {
      return this.setting.devices.reduce((groups, device) => {
        if (!device.group) return groups
        if (!groups.find(group => group.uuid === device.group.uuid)) groups.push(device.group)
        return groups
      }, [...this.setting.deviceGroups])
    },
    maxChangeSettings () {
      return this.setting.maxChangeSettings.map(setting => ({
        ...setting,
        window: moment(setting.timeWindow).utc().format('HH:mm'),
      })).filter(setting => setting.maxDrop != null || setting.maxRise != null)
    },
    deviceGroupString () {
      return this.deviceGroups.reduce((string, group) => {
        if (string) return `${string}, ${group.name}`
        return group.name
      }, '')
    },
  },
  methods: {
    async openPopover ($event) {
      this.popover = await this.$dropdownMenu($event, [{
        text: this.$t('conditions.alert settings.Edit'),
        handler: () => this.$emit('edit'),
      }, {
        text: this.$t('common.Delete'),
        handler: () => this.deleteAlert(),
      }])
    },
    toggleOpen () {
      this.isOpen = !this.isOpen
    },
    async toggleEnabled () {
      const { errors } = await alertMutations.updateSetting({
        uuid: this.setting.uuid,
        alertType: this.setting.type,
        name: this.setting.name,
        maxChangeSettings: this.setting.maxChangeSettings.map(setting => ({
          maxDrop: setting.maxDrop,
          maxRise: setting.maxRise,
          sensorType: setting.sensorType,
          timeWindow: setting.timeWindow,
          uuid: setting.uuid,
        })),
        isEnabled: !this.setting.isEnabled,
      })
      if (errors) {
        return this.$showGraphQLErrors(errors)
      }
      this.$emit('refetch')
    },
    async deleteAlert () {
      const { errors } = await alertMutations.deleteSetting(this.setting.uuid)
      if (errors) {
        return this.$showGraphQLErrors(errors)
      }
      this.$emit('refetch')
    },
  },
}
</script>
<style lang="scss" scoped>
ion-toggle {
  zoom: 0.75;
  @media only screen and (min-width: 768px) {
    zoom: 0.5;
  }
  padding-right: 5px;
}

.border-bottom {
  border-bottom: 1px solid var(--ion-color-light)
}

ion-button {
  --background-hover: transparent;
  --background-hover-opacity: transparent;
}

.drawer ion-row:nth-child(even) {
  background: var(--ion-color-light)
}

.drawer ion-row:last-child {
  background: transparent
}
</style>
